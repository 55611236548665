<template>
    <div class="menu">
        <v-app-bar color="white" fixed height="125px" class="mt-md-8 mt-lg-8">
            <v-container class="mt-4">
                <!--web Screen start-->
                <div class="d-none d-sm-none d-md-block d-lg-block d-xl-block">
                    <v-row justify="space-around" id="container" ref="container" class="mt-5">
                        <v-col cols="12" lg="3">
                            <img alt="Vue logo" src="../assets/logo.png" height="60" contain />
                        </v-col>
                        <v-col cols="12" lg="6" large>
                            <div justify="center">
                                <v-btn v-for="(menu, i) in menus" :key="i" color="black" text class="my-2"
                                    :href="menu.href">
                                    {{ menu.text }}
                                </v-btn>
                            </div>
                        </v-col>
                        <v-col cols="5" lg="3" class="d-flex justify-end flex-row text-wrap">
                            <v-btn icon v-if="cartQty" class="mb-8" href="checkout" raised>
                                <v-badge color="black" :content="cartQty" overlap offset-x="10" offset-y="10">
                                    <v-icon color="black">mdi-cart-outline</v-icon>
                                </v-badge>
                            </v-btn>
                            <v-btn icon v-if="cartQty == ''" class="mb-8" href="checkout" raised>
                                <v-badge color="black" content="0" overlap offset-x="10" offset-y="10" bordered>
                                    <v-icon color="black">mdi-cart-outline</v-icon>
                                </v-badge>
                            </v-btn>
                            <v-btn text class="mb-8" href="checkout">
                                <div class="d-none d-sm-none d-md-block d-lg-block d-xl-block">
                                    NET TOTAL :
                                </div>
                                ₹ {{ productSalesPrice }}
                            </v-btn>
                            <!-- <v-btn outlined color="primary" @click="contactDialog = true"
              ><v-icon color="primary" small class="mr-2">mdi-phone</v-icon
              >Contacts</v-btn
            > -->
                        </v-col>
                    </v-row>
                </div>
                <!--web Screen end-->

                <!--Mobile Screen-->
                <div class="d-block d-sm-block d-md-none d-lg-none d-xl-none">
                    <v-row dense>
                        <v-col cols="12" lg="3">
                            <img alt="Vue logo" src="../assets/logo.png" height="50" contain class="mt-5" />
                        </v-col>
                        <v-col cols="7" lg="3">
                            <div class="mt-3 font-weight-bold"> <v-breadcrumbs :items="menus"></v-breadcrumbs>
                            </div>
                        </v-col>
                        <v-col cols="5" lg="3" class="d-flex justify-end flex-row text-wrap">
                            <v-btn icon v-if="cartQty" class="mb-8" href="checkout" raised>
                                <v-badge color="black" :content="cartQty" overlap offset-x="10" offset-y="10">
                                    <v-icon color="black">mdi-cart-outline</v-icon>
                                </v-badge>
                            </v-btn>
                            <v-btn icon v-if="cartQty == ''" href="checkout" class="mb-8" raised>
                                <v-badge color="black" content="0" overlap offset-x="10" offset-y="10" bordered>
                                    <v-icon color="black">mdi-cart-outline</v-icon>
                                </v-badge>
                            </v-btn>
                            <v-btn text class="mb-8" href="checkout">
                                <div class="d-none d-sm-none d-md-block d-lg-block d-xl-block">
                                    NET TOTAL :
                                </div>
                                ₹ {{ productSalesPrice }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>
                <!--Mobile Screen end-->
            </v-container>
            <v-btn fab tile color="amber accent-4" bottom right absolute v-if="cartQty"
                class="mr-n4 mb-n15 rounded-lg rounded-r-0 d-none d-sm-none d-md-block d-lg-block d-xl-block"
                height="45" href="checkout">
                <v-badge color="black" left :content="cartQty" overlap offset-x="0" offset-y="0" class="mt-2">
                    <v-icon color="white" size="30">mdi-cart-variant</v-icon>
                </v-badge>
            </v-btn>

            <v-btn fab tile color="amber accent-4" bottom right absolute v-if="cartQty == ''"
                class="mr-n4 mb-n15 rounded-lg rounded-r-0 d-none d-sm-none d-md-block d-lg-block d-xl-block"
                height="45" href="checkout">
                <v-badge color="black" left content="0" overlap offset-x="0" offset-y="0" class="mt-2">
                    <v-icon color="white" size="30">mdi-cart-variant</v-icon>
                </v-badge>
            </v-btn>
        </v-app-bar>
        <v-dialog v-model="contactDialog" width="500">
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    Contact Details
                </v-card-title>
                <v-card-text>
                    <ContactDetail />
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" outlined @click="contactDialog = false">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>


</template>

<style scoped>
.v-breadcrumbs {
    padding: 0px !important;
}

.top-bar {
    position: fixed;
    width: 100%;
    z-index: 111;
}

.v-application p {
    margin-bottom: 0px;
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
    name: "Home",
    data: () => ({
        contactDialog: false,
        menus: [
            {
                text: "Home",
                disabled: false,
                href: "/",
            },
            {
                text: "Contact",
                disabled: false,
                href: "contactus",
            },
        ],
    }),
    computed: {
        ...mapGetters([
            "getCart",
        ]),
        cartQty: function () {
            return this.getCart.cartQty;
        },
        productSalesPrice: function () {
            return this.getCart.totalSalesPrice;
        },
    },
    created() {
        this.cartDetails();
    },
    methods: {
        ...mapActions([
            "cartDetails",
        ]),
    }

}
</script>