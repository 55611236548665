<template>
  <div>
    <v-container>
      <v-row>
        <!-- <v-col cols="12" sm="12" md="1" lg="1">
          <span class="black--text"></span>
        </v-col> -->
        <v-col cols="12" sm="12" md="4" lg="4" class="mt-2 mb-2">
          <span class="primary--text font-weight-bold"><span class="primary--text"><v-icon color="primary"
                medium>mdi-phone</v-icon>
              Call : <span v-html="getFooterDetails.address2"></span> </span></span>
        </v-col>
        <v-col cols="12" sm="12" md="2" lg="2">
          <span class="black--text"></span>
        </v-col>
        <v-col cols="12" sm="12" md="1" lg="2">
          <span class="black--text"></span>
        </v-col>
        <v-col cols="12" sm="12" md="4" lg="3" class="mt-2 mb-2">
          <!-- <span class="white--text"><v-icon color="#FFF" small>mdi-email</v-icon></span> -->
        </v-col>
        <v-col cols="12" sm="12" md="1" lg="1">
          <span class="black--text"></span>
        </v-col>
      </v-row>
    </v-container>

  </div>

</template>



<script>
// @ is an alias to /src
import { mapActions, mapGetters } from "vuex";

export default {
  name: "HeaderBar",
  computed: {
    ...mapGetters(["getFooterDetails"]),
  },
  created() {
    this.fetchFooter();
  },
  methods: {
    ...mapActions(["fetchFooter"]),
  },
};
</script>