<template>
    <div class="home">
        <div class="amber accent-4 d-none d-sm-none d-md-block d-lg-block d-xl-block top-bar">
            <HeaderBar />
        </div>
        <div>
            <Menu />
        </div>

        <v-container>
            <div class="productdiv" ref="productdiv1"></div>
            <v-card class="mt-1 mx-auto" height="150" elevation="0">
                <div class="
                d-flex
                flex-column
                fill-height
                justify-center
                align-center
                text-black
              ">
                    <h1 class="primary--text">
                        {{ banner.title }}
                    </h1>
                </div>
            </v-card>

            <v-card class="mx-auto" elevation="0">
                <div class="d-flex flex-column fill-height justify-center align-center text-black  ">
                    <div v-html="banner.details" class="text-justify"></div>
                </div>
            </v-card>
        </v-container>

        <v-dialog v-model="errorDialog" max-width="590">
            <v-card>
                <v-card-title class="text-h5"> </v-card-title>
                <v-card-text>
                    <v-alert text prominent type="error" v-if="errorResponse">
                        <div v-for="(error, i) in cartError.response" :key="i">
                            <h3 v-html="error"></h3>
                        </div>
                    </v-alert>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="errorDialog = false">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="checkoutDialog" persistent transition="dialog-top-transition" width="900">
            <v-card>
                <v-card-title class="text-h5 red darken-2 white--text">
                    Checkout
                </v-card-title>

                <v-card-text class="mt-5">
                    <v-list three-line>
                        <template>
                            <div v-for="(category, i) in products" :key="i" class="product-cart-div">
                                <div v-for="(product, i) in category.products" :key="i" class="product-cart-div">
                                    <v-list-item v-if="product.qty > 0">
                                        <v-card outlined class="mx-auto product-cart-card">
                                            <v-list-item-content>
                                                <v-row>
                                                    <v-col cols="12" sm="10" md="10">
                                                        <v-list-item-subtitle
                                                            class="text--primary font-weight-bold font-label">
                                                            <v-row>
                                                                <v-col cols="12" sm="6" md="6">
                                                                    <h3 class="text-wrap ml-2" style="max-width: 25rem">
                                                                        {{ product.name }}
                                                                    </h3>
                                                                </v-col>
                                                                <v-col cols="12" sm="6" md="6">
                                                                    <div class="
                                          d-flex
                                          justify-space-between
                                          flex-row
                                          text-wrap
                                          ml-2
                                          mr-2
                                        ">
                                                                        <h3>Rs. {{ product.salesprice }}</h3>
                                                                        <h3>Qty. {{ product.qty }}</h3>
                                                                        <h3>Rs. {{ product.totalSalesPrice }}</h3>
                                                                    </div>
                                                                </v-col>
                                                            </v-row>
                                                        </v-list-item-subtitle>
                                                    </v-col>

                                                    <!--<v-col cols="12" sm="2" md="2">
                                  <v-list-item-avatar size="80" tile>
                                    <v-img :src="product.image"></v-img>
                                  </v-list-item-avatar>
                                </v-col>-->
                                                </v-row>
                                            </v-list-item-content>
                                        </v-card>
                                    </v-list-item>
                                </div>
                            </div>
                        </template>
                    </v-list>
                </v-card-text>

                <v-card-text>
                    <!--alert box-->
                    <!-- <v-alert text prominent type="error" v-if="errorResponse">
                  <div v-for="(error, i) in cartError.response" :key="i">
                    <h3 v-html="error"></h3> 
                  </div>
                </v-alert>-->
                    <!--alert box end-->

                    <!--alert box-->
                    <v-alert text prominent type="success" v-if="successResponse" class="mt-4">
                        <div>
                            {{ cartError }}
                            <v-btn outlined @click="refereshPage()" color="primary" class="ml-15">close</v-btn>
                        </div>
                    </v-alert>
                    <!--alert box end-->

                    <v-row>
                        <v-col cols="12" sm="12" md="12">
                            <form class="mt-5" v-if="!successResponse">
                                <v-row>
                                    <v-col cols="12" sm="8" md="8">
                                        <v-text-field v-model="mobileno" :error-messages="mobilenoErrors"
                                            label="Mobileno" required outlined @input="$v.mobileno.$touch()"
                                            @blur="$v.mobileno.$touch()"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4" md="4">
                                        <v-btn class="ma-2 float-left" outlined color="primary" @click="sendOtp"
                                            width="150" height="40">
                                            <span class="black--text">GET OTP</span>
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6" class="mb-5">
                                        <h3 class="black--text mb-5 text--disabled">
                                            ENTER OTP HERE :
                                        </h3>
                                        <v-otp-input v-model="otp" type="number" :error-messages="otpErrors"
                                            @input="$v.otp.$touch()" @blur="$v.otp.$touch()"></v-otp-input>
                                    </v-col>
                                </v-row>
                                <v-text-field v-model="name" :error-messages="nameErrors" label="Name" required outlined
                                    @input="$v.name.$touch()" @blur="$v.name.$touch()"></v-text-field>
                                <v-text-field v-model="email" :error-messages="emailErrors" label="E-mail" required
                                    outlined @input="$v.email.$touch()" @blur="$v.email.$touch()"></v-text-field>

                                <v-text-field v-model="state" :error-messages="stateErrors" label="State" required
                                    outlined @input="$v.state.$touch()" @blur="$v.state.$touch()"></v-text-field>

                                <v-text-field v-model="deliverycity" :error-messages="deliverycityErrors"
                                    label="Delivery City" required outlined @input="$v.deliverycity.$touch()"
                                    @blur="$v.deliverycity.$touch()"></v-text-field>

                                <v-textarea v-model="address" label="Address" required outlined
                                    @input="$v.address.$touch()" @blur="$v.address.$touch()"
                                    :error-messages="addressErrors"></v-textarea>

                                <v-row justify="space-around" id="container" ref="container">
                                    <v-col cols="12" sm="4" md="4">
                                        <v-text-field v-model="productRate" outlined label="Rate"
                                            readonly></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4" md="4">
                                        <v-text-field v-model="productOfferPrice" outlined label="Offer Price"
                                            readonly></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4" md="4">
                                        <v-text-field v-model="productSalesPrice" outlined label="Sales Price"
                                            readonly></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-checkbox v-model="checkbox" :error-messages="checkboxErrors" label="Order Confirm?"
                                    required @change="$v.checkbox.$touch()" @blur="$v.checkbox.$touch()"></v-checkbox>
                                <v-divider></v-divider>
                                <v-btn class="mr-4 mt-5" @click="submitCheckout" outlined :disabled="chechoutbtn">
                                    submit
                                </v-btn>
                                <v-btn @click="checkoutDialog = false" color="primary" outlined class="mr-4 mt-5">
                                    close
                                </v-btn>
                            </form>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

        <loading v-if="loading" />

        <v-container>
            <v-card class="mx-auto d-none" outlined>
                <v-card-text>
                    <!--alert box-->
                    <v-alert text prominent type="success" v-if="successResponse" class="mt-4">
                        <div>
                            {{ cartError }}
                            <v-btn outlined @click="refereshPage()" color="primary" class="ml-15">close</v-btn>
                        </div>
                    </v-alert>
                    <!--alert box end-->

                    <form class="mt-5" v-if="!successResponse">
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field v-model="mobileno" :error-messages="mobilenoErrors" label="Mobileno"
                                    required outlined @input="$v.mobileno.$touch()"
                                    @blur="$v.mobileno.$touch()"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4" md="4">
                                <v-btn class="ma-2 float-left" outlined color="primary" @click="sendOtp" width="150"
                                    height="40">
                                    <span class="black--text">GET OTP</span>
                                </v-btn>
                            </v-col>
                            <v-col cols="12" sm="4" md="4" class="mb-2">
                                <h3 class="black--text mb-5 text--disabled">
                                    ENTER OTP HERE :
                                </h3>
                                <v-otp-input v-model="otp" type="number" :error-messages="otpErrors"
                                    @input="$v.otp.$touch()" @blur="$v.otp.$touch()"></v-otp-input>
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row justify="space-around" id="container" ref="container" class="mt-2">
                            <v-col cols="12" sm="6" md="6">
                                <v-row justify="space-around" id="container" ref="container">
                                    <v-col cols="12" sm="4" md="6">
                                        <v-text-field v-model="name" :error-messages="nameErrors" label="Name" required
                                            outlined @input="$v.name.$touch()" @blur="$v.name.$touch()"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4" md="6">
                                        <v-text-field v-model="email" :error-messages="emailErrors" label="E-mail"
                                            required outlined @input="$v.email.$touch()"
                                            @blur="$v.email.$touch()"></v-text-field>
                                    </v-col>
                                    <!--<v-col cols="12" sm="4" md="4">
                          <v-text-field v-model="mobileno" :error-messages="mobilenoErrors" label="Mobileno" required outlined
                            @input="$v.mobileno.$touch()" @blur="$v.mobileno.$touch()"></v-text-field>
                        </v-col>-->
                                    <v-col cols="12" sm="4" md="6">
                                        <v-text-field v-model="state" :error-messages="stateErrors" label="State"
                                            required outlined @input="$v.state.$touch()"
                                            @blur="$v.state.$touch()"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4" md="6">
                                        <v-text-field v-model="deliverycity" :error-messages="deliverycityErrors"
                                            label="Delivery City" required outlined @input="$v.deliverycity.$touch()"
                                            @blur="$v.deliverycity.$touch()"></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-textarea v-model="address" label="Address" required outlined
                                    @input="$v.address.$touch()" @blur="$v.address.$touch()"
                                    :error-messages="addressErrors"></v-textarea>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-row justify="space-around" id="container" ref="container">
                                    <v-col cols="12" sm="6" md="6">
                                        <v-text-field v-model="productRate" outlined label="Rate"
                                            readonly></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6">
                                        <v-text-field v-model="productOfferPrice" outlined label="Offer Price"
                                            readonly></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12">
                                        <v-text-field v-model="productSalesPrice" outlined label="Sales Price"
                                            readonly></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>

                        <v-checkbox v-model="checkbox" :error-messages="checkboxErrors" label="Order Confirm?" required
                            @change="$v.checkbox.$touch()" @blur="$v.checkbox.$touch()"></v-checkbox>
                        <v-divider></v-divider>
                        <v-btn class="mr-4 mt-5" @click="submitCheckout" outlined :disabled="chechoutbtn">
                            submit
                        </v-btn>
                    </form>
                </v-card-text>
            </v-card>
        </v-container>

        <!--footer Component-->
        <div>
            <footerCommon />
        </div>

        <!--footer Component-->

    </div>
</template>



<style scoped>
.top-bar {
    position: fixed;
    width: 100%;
    z-index: 111;
}

.v-application p {
    margin-bottom: 0px;
}

.productdiv {
    margin-top: 125px !important;
}

.banner-text {
    line-height: 50px;
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
    name: "Contactus",
    data: () => ({

    }),
    computed: {
        ...mapGetters([
            "getBannerDetails",
        ]),
        banner: function () {
            return this.getBannerDetails;
        },

    },
    created() {
        this.fetchPageDetails("9");
    },
    methods: {
        ...mapActions([
            "fetchPageDetails",
        ]),

    },
};
</script>