<template>
  <div class="home">
    <div class="amber accent-4 d-none d-sm-none d-md-block d-lg-block d-xl-block top-bar">
      <HeaderBar />
    </div>
    <div>
      <Menu />
    </div>
    <v-container>
      <div class="productdiv" ref="productdiv1"></div>
      <v-card class="mt-1 mx-auto" height="150" elevation="0">
        <div class="
            d-flex
            flex-column
            fill-height
            justify-center
            align-center
            text-black
          ">
          <h1 class="primary--text">
            {{ banner.title }}
          </h1>
        </div>
      </v-card>

      <v-card class="mx-auto" elevation="0">
        <div class="
            d-flex
            flex-column
            fill-height
            justify-center
            align-center
            text-black
          ">
          <div v-html="banner.details" class="text-justify"></div>
        </div>
      </v-card>
    </v-container>


    <loading v-if="loading" />



    <!--footer Component-->
    <div>
      <footerCommon />
    </div>

    <!--footer Component-->

    <v-snackbar v-model="snackbar" :timeout="timeout" color="primary" elevation="24">
      {{ text }}
    </v-snackbar>
  </div>
</template>

<style scoped>
.top-bar {
  position: fixed;
  width: 100%;
  z-index: 111;
}

.v-application p {
  margin-bottom: 0px;
}

.productdiv {
  margin-top: 125px !important;
}

.banner-text {
  line-height: 50px;
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";


export default {
  name: "Aboutus",
  data: () => ({
  }),
  computed: {
    ...mapGetters([
      "getBannerDetails",
    ]),
    banner: function () {
      return this.getBannerDetails;
    },

  },
  created() {

    this.fetchPageDetails("5");

  },
  methods: {
    ...mapActions([
      "fetchPageDetails",
    ]),

  },
};
</script>
