<template>
    <div class="home">
        <div class="amber accent-4 d-none d-sm-none d-md-block d-lg-block d-xl-block top-bar">
            <HeaderBar />
        </div>
        <div>
            <Menu />
        </div>
        <v-container>
            <div class="productdiv" ref="productdiv1"></div>
            <v-card class="mt-1 mx-auto" height="80" elevation="0">
                <div class="  d-flex flex-column fill-height justify-center align-center text-black ">
                    <h1 class="primary--text">
                        <!-- {{ banner.title }} -->
                    </h1>
                </div>
            </v-card>
            <v-card>
                <v-card-title class="text-h5 accent darken-2 white--text">
                    Checkout
                </v-card-title>

                <v-card-text>
                    <!--alert box-->
                    <!-- <v-alert text prominent type="error" v-if="errorResponse">
            <div v-for="(error, i) in cartError.response" :key="i">
              <h3 v-html="error"></h3> 
            </div>
          </v-alert>-->
                    <!--alert box end-->

                    <!--alert box-->
                    <v-alert text prominent type="success" v-if="successResponse" class="mt-4">
                        <div>
                            <span v-html="cartError"></span>
                            <!-- <v-btn outlined @click="refereshPage()" color="primary" class="ml-15">close</v-btn> -->

                            <v-btn outlined href="/" color="primary" class="ml-15">close</v-btn>
                        </div>
                    </v-alert>
                    <!--alert box end-->

                    <v-row>
                        <v-col cols="12" sm="12" md="12">
                            <form class="mt-5" v-if="!successResponse">
                                <v-row dense no-gutters>
                                    <v-col cols="12" sm="8" md="8">
                                        <v-text-field type="number" v-model="mobileno" :error-messages="mobilenoErrors"
                                            label="Mobile No" required outlined @input="$v.mobileno.$touch()"
                                            @blur="$v.mobileno.$touch()"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4" md="4">
                                        <v-btn class="ma-md-2 ma-lg-2 mb-5 float-left rounded" raised tile elevation="0"
                                            color="green" @click="sendOtp" width="150" height="40">
                                            <span class="white--text">GET OTP</span>
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="12" sm="9" md="9" class="mb-1 mt-1" v-if="getOTP">
                                        <h3 class="primary--text mb-5">
                                            YOUR OTP : {{ getOTP }}
                                        </h3>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6" class="mb-5">
                                        <h3 class="mb-5" style="color: #2e7d32">
                                            ENTER OTP HERE :
                                        </h3>
                                        <v-otp-input v-model="otp" type="number" :error-messages="otpErrors"
                                            @input="$v.otp.$touch()" @blur="$v.otp.$touch()"></v-otp-input>
                                    </v-col>
                                </v-row>
                                <v-row dense no-gutters>
                                    <v-col cols="12" sm="6" md="6">
                                        <v-text-field v-model="name" :error-messages="nameErrors" label="Name" required
                                            outlined @input="$v.name.$touch()" @blur="$v.name.$touch()"
                                            class="mr-5"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6">
                                        <v-text-field v-model="email" :error-messages="emailErrors" label="E-mail"
                                            required outlined @input="$v.email.$touch()" @blur="$v.email.$touch()"
                                            class="mr-5"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6">
                                        <v-text-field v-model="state" :error-messages="stateErrors" label="State"
                                            required outlined @input="$v.state.$touch()" @blur="$v.state.$touch()"
                                            class="mr-5"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6">
                                        <v-text-field v-model="deliverycity" :error-messages="deliverycityErrors"
                                            label="Delivery City" required outlined @input="$v.deliverycity.$touch()"
                                            @blur="$v.deliverycity.$touch()" class="mr-5"></v-text-field>
                                    </v-col>

                                    <v-col cols="12" sm="6" md="6">
                                        <v-text-field v-model="gstno" label="GST No" outlined
                                            class="mr-5"></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-textarea v-model="address" label="Address" required outlined
                                    @input="$v.address.$touch()" @blur="$v.address.$touch()"
                                    :error-messages="addressErrors"></v-textarea>

                                <v-row justify="space-around" id="container" ref="container">

                                    <v-col cols="12" sm="6" md="6">
                                        <v-text-field v-model="productRate" outlined label="Regular Price"
                                            readonly></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6">
                                        <v-text-field v-model="productOfferPrice" outlined label="Discount"
                                            readonly></v-text-field>
                                    </v-col>
                                    <!-- <v-col cols="12" sm="4" md="4">
                                        <v-text-field v-model="productSalesPrice" outlined label="Sales Price"
                                            readonly></v-text-field>
                                    </v-col> -->

                                    <v-col cols="12" sm="12" md="12">
                                        <v-text-field v-model="total_Price" outlined label="Total Price"
                                            readonly></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-checkbox v-model="checkbox" :error-messages="checkboxErrors" required
                                    @change="$v.checkbox.$touch()" @blur="$v.checkbox.$touch()">
                                    <template v-slot:label>
                                        <div class="primary--text font-weight-black">
                                            Order Confirm?
                                        </div>
                                    </template>
                                </v-checkbox>
                                <v-divider></v-divider>
                                <v-btn href="/" color="error" outlined class="mr-4 mt-5">
                                    Add More
                                </v-btn>


                                <!-- <v-btn raised tile elevation="0" color="primary" class="mr-4 mt-5 rounded"
                                    @click="submitCheckout" :disabled="chechoutbtn">Submit</v-btn> -->

                                
                                <!--old button-->
                                <!-- <v-btn class="mr-4 mt-5" @click="submitCheckout" outlined color="primary"
                                    :disabled="chechoutbtn">
                                    submit
                                </v-btn>
                                <v-btn @click="checkoutDialog = false" color="error" outlined class="mr-4 mt-5">
                                    close
                                </v-btn> -->
                                <!--old button end-->
                            </form>
                        </v-col>
                    </v-row>
                </v-card-text>


                <v-card-text>
                    <div class="mb-5">
                        <h3 class="primary--text font-weight-black mb-2">Disclaimer:</h3>
                        <p> As per the Supreme Court order, online sale of crackers / fireworks are strictly prohibited!
                        </p>
                        <p>Check the products available and get the quote.</p>
                    </div>
                    <h3 class="primary--text font-weight-black mb-5" v-if="getCart.cartQty > 0">Review Order</h3>
                    <v-list three-line>
                        <template>
                            <div v-for="(product, k) in getCart.orderDetails" :key="k" class="product-cart-div">
                                <v-list-item v-if="product.qty > 0" class="pa-0">
                                    <v-card outlined class="mx-auto product-cart-card">
                                        <v-list-item-content>
                                            <v-row dense no-gutters>
                                                <v-col cols="12" sm="12" md="12">
                                                    <v-list-item-subtitle
                                                        class="text--primary font-weight-bold font-label">
                                                        <v-row dense no-gutters>
                                                            <v-col cols="12" sm="4" md="4">
                                                                <h3 class="text-wrap ml-2 mt-3 primary--text"
                                                                    style="max-width: 25rem; ">
                                                                    {{ product.name }}
                                                                </h3>
                                                            </v-col>
                                                            <v-col cols="12" sm="8" md="8">
                                                                <div
                                                                    class=" d-flex justify-space-between flex-row text-wrap ml-2 mr-2">
                                                                    <div>
                                                                        <h3 style="color: #2e7d32" class="mt-3">
                                                                            Rs. {{ product.salesprice }}
                                                                        </h3>
                                                                    </div>

                                                                    <!-- <h3>Qty. {{ product.qty }} </h3> -->
                                                                    <div
                                                                        class="cart-qty-alt rounded-xl  d-none d-md-block d-lg-block d-xl-block  ">
                                                                        <div class="qty-btn d-flex">
                                                                            <v-btn class="ma-1 mt-2 ml-5" x-small
                                                                                outlined fab color="black"
                                                                                @click="decrement(product)">
                                                                                <v-icon outlined fab
                                                                                    size="10">mdi-minus</v-icon>
                                                                            </v-btn>
                                                                            <v-text-field outlined v-model="product.qty"
                                                                                dense class="mt-1 cart-qty-textbox"
                                                                                @change="productQtyUpdate(product)"
                                                                                type="number"></v-text-field>
                                                                            <v-btn class="ma-1 mt-2" x-small outlined
                                                                                fab color="black"
                                                                                @click="increment(product)">
                                                                                <v-icon outlined fab
                                                                                    size="10">mdi-plus</v-icon>
                                                                            </v-btn>
                                                                        </div>
                                                                    </div>

                                                                    <div
                                                                        class="  mobile-cart-qty-alt rounded-xl  d-block d-md-none d-lg-none d-xl-none ">
                                                                        <div class="qty-btn d-flex">
                                                                            <v-btn class="ma-1 mt-2" x-small outlined
                                                                                fab color="black"
                                                                                @click="decrement(product)">
                                                                                <v-icon outlined fab
                                                                                    size="10">mdi-minus</v-icon>
                                                                            </v-btn>
                                                                            <v-text-field outlined v-model="product.qty"
                                                                                dense class="mt-1 cart-qty-textbox"
                                                                                @change="productQtyUpdate(product)"
                                                                                type="number"></v-text-field>
                                                                            <v-btn class="ma-1 mt-2" x-small outlined
                                                                                fab color="black"
                                                                                @click="increment(product)">
                                                                                <v-icon outlined fab
                                                                                    size="10">mdi-plus</v-icon>
                                                                            </v-btn>
                                                                        </div>
                                                                    </div>

                                                                    <div>
                                                                        <h3 class="accent--text mt-3">
                                                                            Rs. {{ product.totalSalesPrice }}
                                                                        </h3>
                                                                    </div>


                                                                </div>
                                                            </v-col>
                                                        </v-row>
                                                    </v-list-item-subtitle>
                                                </v-col>

                                                <!--<v-col cols="12" sm="2" md="2">
                            <v-list-item-avatar size="80" tile>
                              <v-img :src="product.image"></v-img>
                            </v-list-item-avatar>
                          </v-col>-->
                                            </v-row>
                                        </v-list-item-content>
                                    </v-card>
                                </v-list-item>
                            </div>
                        </template>
                    </v-list>
                </v-card-text>
            </v-card>
        </v-container>

        <!--Cart Error-->
        <v-dialog v-model="errorDialog" max-width="590">
            <v-card>
                <v-card-title class="text-h5"> </v-card-title>
                <v-card-text>
                    <v-alert text prominent type="error" v-if="errorResponse">
                        <div v-for="(error, i) in cartError.response" :key="i">
                            <h3 v-html="error"></h3>
                        </div>
                    </v-alert>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn href="/" color="primary" outlined>
                        Add More
                    </v-btn>
                    <v-btn color="error" outlined @click="errorDialog = false">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--Cart Error-->

        <loading v-if="loading" />

        <!--footer Component-->
        <div>
            <footerCommon />
        </div>

        <!--footer Component-->

        <v-snackbar v-model="snackbar" :timeout="timeout" color="primary" elevation="24">
            {{ text }}
        </v-snackbar>
    </div>
</template>

<style scoped>
.v-breadcrumbs {
    padding: 0px !important;
}

.top-bar {
    position: fixed;
    width: 100%;
    z-index: 111;
}

.v-application p {
    margin-bottom: 0px;
}

.qty-alt {
    border: 1px solid #ddd;
    width: 100%;
    text-align: center;
    font-weight: 500;
    height: 50px;
}

.qty-btn {
    margin-left: 5% !important;
    margin-right: 5% !important;
}

.qty-textbox {
    max-width: 55%;
    text-align: center !important;
}

.qty-textbox .v-text-field {
    text-align: center !important;
}

.productdiv {
    margin-top: 100px !important;
}

.banner-text {
    line-height: 50px;
}

.product-cart-div {
    width: 100%;
    display: block;
    margin: 0 auto;
}

.product-cart-card {
    width: 100%;
    display: block;
    margin: 0 auto;
    margin-bottom: 10px;
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";

import { required, email, numeric } from "vuelidate/lib/validators";
//import store from "../store";

export default {
    name: "Checkout",
    data: () => ({
        // productRate: "0.00",
        // productOfferPrice: "0.00",
        // productSalesPrice: "0.00",
        // total_Price: "0.00",
        // cartQty: 0,
        checkoutDialog: false,
        name: "",
        email: "",
        mobileno: "",
        state: "",
        deliverycity: "",
        gstno: "",
        address: "",
        checkbox: false,
        chechoutbtn: false,
        errorDialog: false,
        contactDialog: false,
        imageDialog: false,
        clickedImage: "",
        otp: "",
        snackbar: false,
        text: "OTP Sent Successfully",
        timeout: 2000,
        menus: [

            {
                text: "Home",
                disabled: false,
                href: "/",
            },

            {
                text: "Contact",
                disabled: false,
                href: "contactus",
            },
        ],
    }),
    validations: {
        name: { required },
        email: { required, email },
        mobileno: { required, numeric },
        state: { required },
        deliverycity: { required },
        address: { required },
        otp: { required },
        checkbox: {
            checked(val) {
                return val;
            },
        },
    },
    computed: {
        ...mapGetters([
            "authStatus",
            "authStatusError",
            "authStatusresponse",
            "getProducts",
            "getBannerDetails",
            "getOrderStatus",
            "orderStatusResponse",
            "getOTP",
            "getCart",
        ]),
        checkboxErrors() {
            const errors = [];
            if (!this.$v.checkbox.$dirty) return errors;
            !this.$v.checkbox.checked && errors.push("You must agree to continue!");
            return errors;
        },
        nameErrors() {
            const errors = [];
            if (!this.$v.name.$dirty) return errors;
            !this.$v.name.required && errors.push("Name is required.");
            return errors;
        },
        emailErrors() {
            const errors = [];
            if (!this.$v.email.$dirty) return errors;
            !this.$v.email.email && errors.push("Must be valid e-mail");
            !this.$v.email.required && errors.push("E-mail is required");
            return errors;
        },
        mobilenoErrors() {
            const errors = [];
            if (!this.$v.mobileno.$dirty) return errors;
            !this.$v.mobileno.required && errors.push("Mobileno is required.");
            !this.$v.mobileno.numeric && errors.push("Mobileno is invalid!.");
            return errors;
        },
        stateErrors() {
            const errors = [];
            if (!this.$v.state.$dirty) return errors;
            !this.$v.state.required && errors.push("State is required.");
            return errors;
        },
        deliverycityErrors() {
            const errors = [];
            if (!this.$v.deliverycity.$dirty) return errors;
            !this.$v.deliverycity.required &&
                errors.push("Delivery City is required.");
            return errors;
        },
        addressErrors() {
            const errors = [];
            if (!this.$v.address.$dirty) return errors;
            !this.$v.address.required && errors.push("Address is required.");
            return errors;
        },
        otpErrors() {
            const errors = [];
            if (!this.$v.otp.$dirty) return errors;
            !this.$v.otp.required && errors.push("OTP is required");
            return errors;
        },
        loading: function () {
            return this.authStatus === "loading";
        },
        products: function () {
            return this.getProducts;
        },
        banner: function () {
            return this.getBannerDetails;
        },

        errorResponse: function () {
            console.log(this.getOrderStatus);
            return this.getOrderStatus === "error";
        },
        successResponse: function () {
            console.log(this.getOrderStatus);
            return this.getOrderStatus === "success";
        },
        cartError: function () {
            return this.orderStatusResponse;
        },
        productRate: function () {
            return this.getCart.totalRate;
        },
        productOfferPrice: function () {
            return this.getCart.totalOfferPrice;
        },
        productSalesPrice: function () {
            return this.getCart.totalSalesPrice;
        },
        total_Price: function () {
            return this.getCart.totalSalesPrice;
        },
        cartQty: function () {
            return this.getCart.cartQty;
        },
    },
    created() {
        this.cartDetails();
        this.$store.subscribe((mutation) => {
            //console.log(mutation);
            if (mutation.type === "authError") {
                this.errorDialog = true;
            }

            if (mutation.type === "authRequest") {
                this.chechoutbtn = true;
            }

            if (mutation.type === "authRequest") {
                this.chechoutbtn = true;
            }

            if (
                mutation.type === "orderConfirmed" ||
                mutation.type === "orderConfirmedError"
            ) {
                window.scrollTo(0, 0);
                this.chechoutbtn = false;
            }
        });
    },
    methods: {
        ...mapActions([
            "fetchProducts",
            "fetchPageDetails",
            "orderConfirm",
            "otpAction",
            "addTocart",
            "cartDetails",
        ]),

        increment(product) {
            // store.commit("authRequest");
            product.qty = parseFloat(product.qty) + 1;
            this.addTocart(product);
            this.chechoutbtn = false;
        },
        decrement(product) {
            product.qty = product.qty === 0 ? 0 : parseFloat(product.qty) - 1;
            this.addTocart(product);
            this.chechoutbtn = false;
        },

        refereshPage() {
            window.location.reload();
        },

        sendOtp() {
            //this.$v.$touch();
            if (this.$v.mobileno.$invalid) {
                this.$v.mobileno.$touch();
                console.log(this.mobileno);
                console.log("ERROR");
            } else {
                // do your submit logic here
                setTimeout(() => {
                    const loginData = {
                        mobileno: this.mobileno,
                    };

                    this.otpAction(loginData);

                    this.snackbar = true;
                    this.chechoutbtn = false;
                    // this.checkoutBtnShow = false;
                }, 500);
            }
        },
        submitCheckout() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                console.log("ERROR");
            } else {

                // do your submit logic here
                setTimeout(() => {
                    const orderData = {
                        name: this.name,
                        email: this.email,
                        mobileno: this.mobileno,
                        otp: this.otp,
                        state: this.state,
                        deliverycity: this.deliverycity,
                        address: this.address,
                        gstno: this.gstno,
                    };
                    this.orderConfirm(orderData);
                }, 500);
            }
        },
    },
};
</script>